import * as React from 'react'
import * as css from './Sidebar.module.css'
import { BlogContext } from './BlogContext'
import { MdArrowDropDown } from 'react-icons/md'
import CategoryList from './CategoryList'
import BlogSearch from './BlogSearch'
import Share from './Share'
import Subscribe from './Subscribe'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const Sidebar = () => {
  const { blog, allCategories, $selectedCategory } = React.useContext(BlogContext)

  const [$opened, set$opened] = React.useState(false)

  const category = allCategories.find(({ slug }) => slug.current === $selectedCategory)

  return (
    <aside>
      <div className="sticky">
        <h1 className={`${css.title} h2 cps-navy`} onClick={() => set$opened(!$opened)}>
          {blog.title}
          <MdArrowDropDown className={clsx(css.icon, $opened && css.opened, 'tablet')} />
        </h1>
        <div className={`${css.category} tablet`} key={category?.title || null}>
          <span>{category?.title || 'All Topics'}</span>
        </div>
        <div className={clsx(css.menu, $opened && css.opened)}>
          <CategoryList />
          <BlogSearch />
          <Share />
          <Subscribe />
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
