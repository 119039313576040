import * as React from 'react'
import * as css from './Subscribe.module.css'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Label, Input, Button } from 'components/common/InputWithButton'

const Subscribe = () => (
  <div>
    <p>Subscribe for insider news & expert tips on Insurance Services</p>

    <MailchimpSubscribe
      url="https://cpsinsurance.us2.list-manage.com/subscribe/post?u=57bf072d5c356280c259d2202&id=ae167adb4c"
      render={({ subscribe, status, message }) => (
        <SubscribeForm
          status={status}
          message={message}
          onValidated={subscribe}
        />
      )}
    />
  </div>
)

const SubscribeForm = ({ status, message, onValidated }) => {
  let email = React.useRef(null)

  const submit = () => email.current.validity.valid &&
    onValidated({
      MERGE0: email.current.value,
      MERGE1: '',
      MERGE2: '',
    })

  function handleKeyUp(e) {
    if (e.key === 'Enter') submit()
  }

  return <>
    <Label>
      <Input ref={email} onKeyUp={handleKeyUp} type="email" placeholder="Email" />

      <Button className={css.btn} onClick={submit}>
        Subscribe
      </Button>
    </Label>

    {status === 'sending' && <div>Sending...</div>}
    {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}
    {status === 'success' && <div dangerouslySetInnerHTML={{ __html: message }} />}
  </>
}

export default Subscribe
