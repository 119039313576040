import * as React from 'react'
import * as css from './CategoryList.module.css'
import { BlogContext } from './BlogContext'
import clsx from 'clsx'

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

const CategoryItem = ({ id = false, children }) => {
  const {
    $selectedCategory, set$selectedCategory,
    set$limit,
  } = React.useContext(BlogContext)

  function handleClick() {
    set$selectedCategory(id)
    set$limit(6)

    if (typeof window !== 'undefined') {
      window.history.pushState(null, null, id ? `/news/?category=${ id }` : '/news/')
    }
  }

  return (
    <li
      className={clsx(css.item, $selectedCategory === id && css.active)}
      onClick={handleClick}
    >
      {children}
    </li>
  )
}

const CategoryList = () => {
  const { allCategories } = React.useContext(BlogContext)

  return (
    <ul className={css.list}>
      <CategoryItem>All Topics</CategoryItem>

      {allCategories.map((category, key) => (
        <CategoryItem id={category.slug.current} key={key}>
          {category.title}
        </CategoryItem>
      ))}
    </ul>
  )
}

export default CategoryList
