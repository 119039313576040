import * as React from 'react'
import * as css from './BottomHero.module.css'
import { useAuth0 } from '@auth0/auth0-react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SanityBlock from 'components/common/SanityBlock'
import { Link } from 'gatsby'

const BottomHero = ({ title, _rawDescription, image }) => {
  const { loginWithRedirect } = useAuth0()

  return (
    <section className={`${css.root} padding-x`}>
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt="" draggable={false}
      />

      <div className="container">
        <div className={`${css.txt} styled-corner-left`}>
          <div className={`${css.inner} richtext`}>
            <h1 className={`${css.title} h1 cps-blue`}>{title}</h1>
            <SanityBlock body={_rawDescription} />
            <div className={css.actions}>
              <Link to="/register" className={`${css.btn} action`}>Register Now</Link>
              <button className={`${css.btn} button`} onClick={loginWithRedirect}>Sign in</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BottomHero
