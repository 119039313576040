import * as React from 'react'
import * as css from './BlogListing.module.css'
import { BlogContext } from './BlogContext'
import ArticlePreview from './ArticlePreview'

const BlogListing = () => {
  const { allPosts, $selectedCategory } = React.useContext(BlogContext)

  const posts = !!$selectedCategory
    ? allPosts.filter(post => post.category.map(({ slug }) => slug.current).includes($selectedCategory))
    : allPosts

  const [firstPost, ...theRest] = posts

  const [$limit, set$limit] = React.useState(6)

  return (
    <section>
      {firstPost && <ArticlePreview post={firstPost} large key={firstPost.title} />}

      {!!theRest.length && <>
        <ul className={css.list}>
          {theRest.map(((post, key) => (
            (key < $limit) && (
              <li key={post.title}>
                <ArticlePreview post={post} />
              </li>
            )
          )))}
        </ul>

        {($limit < posts.length) && (
          <div className={`${css.more} text-center`}>
            <button className="button" onClick={() => set$limit($limit + 6)}>
              View more articles +
            </button>
          </div>
        )}
      </>}

      {posts.length === 0 && (
        <div className="text-center text-light">
          No articles found.
        </div>
      )}
    </section>
  )
}

export default BlogListing
