import * as React from 'react'
import * as css from './index.module.css'
import { BlogProvider, BlogContext } from 'components/blog/BlogContext'
import DefaultLayout from 'layouts/Default'
import Sidebar from 'components/blog/Sidebar'
import BlogListing from 'components/blog/BlogListing'
import BottomHero from 'components/blog/BottomHero'
import { graphql, useStaticQuery } from 'gatsby'

const App = () => {
  const { sanityBlogSettings: pg } = useStaticQuery(query)

  const { set$selectedCategory } = React.useContext(BlogContext)

  React.useEffect(() => {
    if (typeof window === 'undefined') return

    let params = new URLSearchParams(window.location.search)
    set$selectedCategory(params.get('category'))
  }, [set$selectedCategory])

  return (
    <DefaultLayout {...pg.seo}>
      <div className={`${css.root} container padding`}>
        <Sidebar />
        <BlogListing />
      </div>

      {!!pg.bottomHero && <BottomHero {...pg.bottomHero} />}
    </DefaultLayout>
  )
}

const BlogPg = () => (
  <BlogProvider>
    <App />
  </BlogProvider>
)

export default BlogPg

const query = graphql`query BlogPg {
  sanityBlogSettings {
    bottomHero { ...hero }
    seo {
      title
      description
    }
  }
}`
